<template>
  <div>
    <CRow>
      <CCol md="7">
        <h4> Transaction Request History
        </h4>
      </CCol>
      <CCol md="5">
        <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />
      </CCol>
    </CRow>

    <div class="table-responsive scroll-r">
      <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="createDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

        <template v-slot:cell(createDate)="{ item }">
          {{ item.createDate | dateWithTime }}
        </template>

        <template v-slot:cell(amount)="{ item }">
          {{ item.amount | formatNum }}
        </template>

        <template v-slot:cell(userName)="{ item }">
          {{ item.userName }} <p> {{ item.userMobile }} </p>
        </template>

        <template v-slot:cell(direction)="{ item }">
          <label class="badge text-uppercase"> {{ item.direction == 'CREDIT_USER' ? 'credit' : 'debit' }} </label>
        </template>

        <template v-slot:cell(earmarkRecord)="{ item }">
          <b v-if="item.earmarkRecord !== false">{{ item.earmarkFeature }}</b> <label class="badge badge-warning" v-if="item.earmarkRecord !== false">{{ item.earmarkExpiry | dateWithTime }}</label>
        </template>

        <template v-slot:cell(customerMessage)="{ item }">
          {{ item.customerMessage | custMsgValue }}
        </template>

        <template v-slot:cell(reason)="{ item }">
          <label class="badge text-uppercase"> {{ item.reason.split('|').length > 1 ? item.reason.split('|')[0] : item.reason }} </label>
        </template>

        <template v-slot:cell(type)="{ item }">
          <label class="badge text-uppercase"> {{ item.reason.split('|').length > 1 ? item.reason.split('|')[1] : 'Cash' }} </label>
        </template>

        <template v-slot:cell(status)="{ item }">
          <label :class="(item.status === 'APPROVED') ? 'badge badge-success' : 'badge badge-danger'">{{ item.status }}</label>
        </template>

      </b-table>
    </div>

    <div>
      <b-row>
        <b-col sm="3">
          <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
        </b-col>
        <b-col sm="9">
          <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
          </b-form-select>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import TransactService from '@/api/TransactService.js';
export default {
  name: "history",
  data() {
    return {
      spinner:false,
      firstActiveTab:true,
      formReason:false,
      transactionRef:null,
      reason:null,
      action:null,
      list:[],
      columns : [],
      data : [],
      items:[],
      fields: [
        { key: 'createDate', label : 'Date Time', sortable: true },
        { key: 'userName', label : 'User', sortable: true },
        { key: 'currency', label : 'Currency', sortable: true },
        { key: 'amount', label : 'Amount', sortable: true },
        { key: 'direction', label : 'Type', sortable: true },
        { key: 'earmarkRecord', label : 'Earmark', sortable: true },
        { key: 'reason', label : 'Reason', sortable: false },
        { key: 'customerMessage', label : 'Message', sortable: true },
        { key: 'type', label : 'Type', sortable: false },
        { key: 'comment', label : 'Bank Ref', sortable: false },
        { key: 'requester', label : 'Requester', sortable: true },
        { key: 'status', label : 'Status', sortable: false },
        { key: 'approver', label : 'Approver', sortable: false }
      ],
      currentPage: 1,
      perPage: 10,
      filter: null,
      seen:false,
      msg :'',
      color:''
    };
  },
  filters: {
    upper(value){
      if(!value) return ''
      value = value.toString()
      return value.toUpperCase()
    },
    lower(value){
      if(!value) return ''
      value = value.toString()
      return value.toLowerCase()
    },
    dateSubstr(value){
      if(!value) return ''
      value = value.toString()
      return value.substring(0,10)
    },
    IdSubstr(value){
      if(!value) return ''
      value = value.toString()
      return value.substring(0,5) + '...'
    },
    dateWithTime(value){
      if(!value) return ''
      var temp = value.toString().split(".");
      value = temp[0].replace("T"," ");
      return value;
    },
    formatNum(value){
      if(!value) return 0
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    },
    custMsgValue(value) {
      let value2 = "";
      switch (value) {
        case '1': value2 =  "Top-up by ATM Cash Deposit";break;
        case '2': value2 =  "Top-up without top-up Code";break;
        case '3': value2 =  "Reward for Moolahgo Promotion";break;
        case '4': value2 =  "Reward for Identity Verification";break;
        case '5': value2 =  "Refund due to Debit error";break;
        case '6': value2 =  "Refund from Trx Cancellation";break;
        default: value2 = value;
      }
      return value2;
    }
  },

  created () {
    this.getHistory();
  },
  methods: {

    getFilterData(){
      this.data  = this.list;
    },

    showFormAction(id,action){
      this.action = action;
      this.transactionRef = id;
      this.formReason = true;
    },

    getHistory: function() {
      TransactService.systemTransactionFindHistory().then(resp => { //console.log(resp);
        this.items = resp.payload;
      }, error => { this.loading = false; console.log(error) });
    }

  }
};

</script>
<style>
.scroll-r{ overflow-y:scroll; }
</style>