<template>
  <div>
    <CRow>
      <CCol md="7">
        <h4> Transaction Request Approval</h4>
      </CCol>
      <CCol md="5">
        <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" class="text-left">
        <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
      </CCol>
    </CRow>
    <div class="table-responsive scroll-r">
      <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="createDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

        <template v-slot:cell(createDate)="{ item }">
          {{ item.createDate | dateWithTime }}
        </template>

        <template v-slot:cell(amount)="{ item }">
          {{ item.amount | formatNum }}
        </template>

        <template v-slot:cell(userName)="{ item }">
          {{ item.userName }} <p> {{ item.userMobile }} </p>
        </template>

        <template v-slot:cell(direction)="{ item }">
          <label class="badge text-uppercase"> {{ item.direction == 'CREDIT_USER' ? 'credit' : 'debit' }} </label>
        </template>

        <template v-slot:cell(earmarkRecord)="{ item }">
          <b v-if="item.earmarkRecord !== false">{{ item.earmarkFeature }}</b> <label class="badge badge-warning" v-if="item.earmarkRecord !== false">{{ item.earmarkExpiry | dateWithTime }}</label>
        </template>

        <template v-slot:cell(customerMessage)="{ item }">
          {{ item.customerMessage | custMsgValue }}
        </template>

        <template v-slot:cell(reason)="{ item }">
          <label class="badge text-uppercase"> {{ item.reason.split('|').length > 1 ? item.reason.split('|')[0] : item.reason }} </label>
        </template>

        <template v-slot:cell(type)="{ item }">
          <label class="badge text-uppercase"> {{ item.reason.split('|').length > 1 ? item.reason.split('|')[1] : 'Cash' }} </label>
        </template>

        <template v-slot:cell(actions)="{ item }">
          <component :is="disApproval ? 'span' : 'router-link'" :class="!disApproval ? 'btn btn-success btn-sm' : 'btn btn-success btn-sm disabled'" to="#" exact v-on:click.native="actionApprove(item.id, item)" v-show="item.status === 'PENDING'" :disabled="disApproval" > Approve </component> &nbsp;
          <component :is="disApproval ? 'span' : 'router-link'" to="#" :class="!disApproval ? 'btn btn-danger btn-sm' : 'btn btn-danger btn-sm disabled'" exact v-on:click.native="actionReject(item.id,item)" v-show="item.status === 'PENDING'" :disabled="disApproval"> Reject </component>
          <label :class="(item.status === 'APPROVED') ? 'badge badge-success' : 'badge badge-danger'" v-show="item.status !== 'PENDING'">{{ item.status }}</label>
        </template>

      </b-table>
    </div>
    <div>
      <b-row>
        <b-col sm="3">
          <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
        </b-col>
        <b-col sm="9">
          <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
          </b-form-select>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import TransactService from '@/api/TransactService.js';

export default {
  name: "pending",
  components: {},
  data() {
    return {
      spinner:false,
      firstActiveTab:true,
      formReason:false,
      transactionRef:null,
      reason:null,
      action:null,
      list:[],
      columns : [],
      data : [],
      items:[],
      fields: [
        { key: 'createDate', label : 'Date Time', sortable: true },
        { key: 'userName', label : 'User', sortable: true },
        { key: 'currency', label : 'Currency', sortable: true },
        { key: 'amount', label : 'Amount', sortable: true },
        { key: 'direction', label : 'Type', sortable: true },
        { key: 'earmarkRecord', label : 'Earmark', sortable: true },
        { key: 'customerMessage', label : 'Message', sortable: true },
        { key: 'reason', label : 'Reason', sortable: false },
        { key: 'type', label : 'Type', sortable: false },
        { key: 'comment', label : 'Bank Ref', sortable: false },
        { key: 'requester', label : 'Request By', sortable: true },
        { key: 'actions', label: 'Actions' }
      ],
      currentPage: 1,
      perPage: 10,
      filter: null,
      seen:false,
      url_mg:'',
      xapikey:'',
      msg :'',
      color:'',
      disApproval:false
    };
  },
  filters: {
    upper(value){
      if(!value) return ''
      value = value.toString()
      return value.toUpperCase()
    },
    lower(value){
      if(!value) return ''
      value = value.toString()
      return value.toLowerCase()
    },
    dateSubstr(value){
      if(!value) return ''
      value = value.toString()
      return value.substring(0,10)
    },
    IdSubstr(value){
      if(!value) return ''
      value = value.toString()
      return value.substring(0,5) + '...'
    },
    dateWithTime(value){
      if(!value) return ''
      var temp = value.toString().split(".");
      value = temp[0].replace("T"," ");
      return value;
    },
    formatNum(value){
      if(!value) return 0
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    },
    custMsgValue(value) {
      let value2 = "";
      switch (value) {
        case '1': value2 =  "Top-up by ATM Cash Deposit";break;
        case '2': value2 =  "Top-up without top-up Code";break;
        case '3': value2 =  "Reward for Moolahgo Promotion";break;
        case '4': value2 =  "Reward for Identity Verification";break;
        case '5': value2 =  "Refund due to Debit error";break;
        case '6': value2 =  "Refund from Trx Cancellation";break;
        default: value2 = value;
      }
      return value2;
    }
  },

  created () {
    this.getSystemConfigList();
    this.getPendingList();
  },
  methods: {

    getFilterData(){
      this.data  = this.list;
    },

    actionCancel(){
      this.action= null;
      this.transactionRef= null;
      this.reason = null;
      this.formReason = false;
    },

    showFormAction(id,action){
      this.action = action;
      this.transactionRef = id;
      this.formReason = true;
    },

    actionApprove: function(id, items) {
      let type_real = (items.direction === 'CREDIT_USER') ? 'Credit' : 'Debit';
      let trans_type = items.hasOwnProperty('reason') && items.reason.split('|').length > 1 ? items.reason.split('|')[1] : 'Cash'
      this.$swal({
        title: "Approve this "+type_real+" Request?",
        text: (trans_type === "Cash") ? "WARNING: Cash transactions have not been validated against the payment register. Please check against Bank account directly" : "Are you sure? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Approve it!"
      }).then((result) => { // <--
        if (result.value) { // <-- if confirmed
            this.disApproval=true
            let params_check = {};
            params_check['bank_reference'] = items.comment;
            TransactService.getMatchingBankReference(this.url_mg, this.xapikey, params_check).then(resp_check => {
              console.log(resp_check);
              if (!resp_check) {
                this.$swal({
                  title: "Transaction is not approved",
                  text: "This transaction is failed to go through payment register because of CORS issue",
                  icon: "warning"
                });
                this.disApproval=false
              } else {
                TransactService.systemTransactionApprove(id).then(async resp => {
                  console.log(resp);
                  if (resp.statusCode === "S0000") {
                    // eslint-disable-next-line no-prototype-builtins,no-empty
                    if (!items.hasOwnProperty('earmarkRecord') || !items.earmarkRecord) {
                      this.$swal({
                        title: "Transaction Request Approved",
                        text: "This transaction is successfully approved",
                        icon: "success"
                      });
                    } else {
                      try {
                        const check_earmark = await AuthService.earMarkByWalletId(items.walletId);
                        console.log(check_earmark);
                        let arr_check = check_earmark.payload.filter(p => p.feature == items.feature);
                        let paramEarmark = {};
                        paramEarmark["expireDate"] = items.earmarkExpiry;
                        if (arr_check.length == 0) {
                          paramEarmark["amount"] = items.amount;
                          paramEarmark["currency"] = items.currency;
                          paramEarmark["feature"] = items.earmarkFeature;
                          paramEarmark["userId"] = items.userId;
                          paramEarmark["walletId"] = items.walletId;
                          AuthService.earMarkSave(paramEarmark).then(resp_earmark => {
                            console.log(resp_earmark);
                            this.$swal({
                              title: "Transaction Request Approved",
                              text: "This transaction with earmark is successfully approved",
                              icon: "success"
                            });
                          }, error => {
                            console.log(error);
                            alert("Approve Success but failed to save Earmark!");
                            this.$swal({
                              title: "Transaction Request Approved with Warning",
                              text: "Approve Success but failed to save Earmark!",
                              icon: "warning"
                            });
                          });
                        } else {
                          paramEarmark["recordId"] = arr_check[0].id;
                          paramEarmark["amount"] = parseFloat(arr_check[0].amount) + parseFloat(items.amount);
                          AuthService.earMarkUpdate(paramEarmark).then(resp_earmark => {
                            console.log(resp_earmark);
                            this.$swal({
                              title: "Transaction Request Approved",
                              text: "This transaction with earmark is successfully approved",
                              icon: "success"
                            });
                          }, error => {
                            console.log(error);
                            this.$swal({
                              title: "Transaction Request Approved with Warning",
                              text: "Approve Success but failed to save Earmark!",
                              icon: "warning"
                            });
                          });
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    }
                    let params_matching = {};
                    if (type_real === "Debit") {
                        params_matching['bank_reference'] = items.comment
                        params_matching['wallet_username'] = items.userMobile
                        params_matching['wallet_reference'] = items.comment
                        params_matching['type'] = 'OUT'
                        const check_matching = await TransactService.setMatchingBankReference(this.url_mg, this.xapikey, params_matching)
                        console.log(check_matching);
                    } else {
                      params_matching['bank_reference'] = items.comment
                      params_matching['wallet_username'] = items.userMobile
                      params_matching['wallet_reference'] = resp.payload['movementRefId']
                      params_matching["wallet_id"] = items.walletId;
                      const check_matching = await TransactService.setMatchingBankReference(this.url_mg, this.xapikey, params_matching)
                      console.log(check_matching);
                    }
                    this.getPendingList();
                  } else {
                    this.$swal({
                      title: "Transaction Request Failed to Approve",
                      text: "This transaction is failed to approved",
                      icon: "error"
                    });
                    this.msg = !resp.data.message ? "Approve Failed!" : resp.data.message;
                    this.color = 'danger';
                    this.seen = true;
                    this.disApproval=false
                  }
                  setTimeout(() => this.seen = false, 5000);
                }, error => {
                  this.$swal({
                    title: "Transaction Request Failed to Approve",
                    text: "This transaction is failed to approved",
                    icon: "error"
                  })
                  this.loading = false
                  this.disApproval=false
                  console.log(error)
                });
              }
            }, error => {
              this.$swal({
                title: "Transaction Request Failed to Approve",
                text: "This transaction is failed to approved",
                icon: "error"
              });
              this.disApproval=false
              console.log(error)
            });

        }
      });

    },

    actionReject: function(id, items) {
      let type_real = (items.direction === 'CREDIT_USER') ? 'Credit' : 'Debit';
      let trans_type = items.reason.split('|').length > 1 ? items.reason.split('|')[1] : 'Cash'
      this.$swal({
        title: "Reject this "+type_real+" Request?",
        text: "Are you sure? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Reject it!"
      }).then((result) => { // <--
        if (result.value) { // <-- if confirmed
          this.disApproval=true
          TransactService.systemTransactionReject(id).then(async resp => {
            console.log(resp);
            if (resp.message === "Success") {
              if (trans_type === "Cash" || type_real === "Credit") {
                if (type_real === "Credit") {
                  let params_unmatch = {}
                  params_unmatch['bank_reference'] = items.comment
                  params_unmatch['unmatching'] = true
                  TransactService.getMatchingBankReference(this.url_mg, this.xapikey, params_unmatch).then(resp_unmatch => {
                    console.log(resp_unmatch)
                  })
                }
                this.$swal({
                  title: "Transaction Request Rejected",
                  text: "This transaction is successfully rejected",
                  icon: "success"
                });
                this.getPendingList();
              } else {
                let params_matching = {};
                params_matching['bank_reference'] = items.comment
                params_matching['wallet_username'] = items.userMobile
                params_matching['wallet_reference'] = items.comment
                params_matching['type'] = 'OUT'
                params_matching['is_rejected'] = true
                TransactService.setMatchingBankReference(this.url_mg, this.xapikey, params_matching).then(resp_update => {
                  console.log(resp_update);
                  this.$swal({
                    title: "Transaction Request Rejected",
                    text: "This transaction is successfully rejected",
                    icon: "success"
                  });
                  this.getPendingList();
                }, error => {
                  console.log(error);
                  this.disApproval=false
                });
              }
            } else {
              this.msg = !resp.data.message ? "Reject Failed!" : resp.data.message
              this.color = 'danger'
              this.seen = true
              this.disApproval=false
            }
            setTimeout(() => this.seen = false, 5000);
          }, error => {
            this.loading = false;
            console.log(error);
          });
        }
      });

    },

    getPendingList: function() {
      this.disApproval=false
      TransactService.systemTransactionFindAll().then(resp => { //console.log(resp);
        this.items = resp.payload;
      }, error => { this.loading = false; console.log(error) });
    },

    getSystemConfigList: function() {
      AuthService.getSystemConfigList().then(resp => {
        // console.log(resp)
        let external_config = JSON.parse(resp.external_api_call_config)
        // console.log(external_config)
        this.url_mg = external_config.hasOwnProperty('mg-api-url-credit') ? external_config['mg-api-url-credit'] : 'https://transact.moolahpay.co/';
        this.xapikey = external_config.hasOwnProperty('x-api-key-credit') ? external_config['x-api-key-credit'] : ''
        console.log(this.url_mg)
        console.log(this.xapikey)
      })
    },

  }
};

</script>

<style>
.scroll-r{ overflow-y:scroll; }
</style>
