<template>
  <div id="app">
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody>
          <CTabs>
            <CTab title="Pending" :active="firstActiveTab !== false"> <br/>
              <pending />
            </CTab>
            <CTab title="History"><br/>
              <history />
            </CTab>
          </CTabs>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  </div>
</template>

<script>
import Pending from './pending';
import History from './history';

export default {
  name: "transactionrequests",
  components: {
    'history' : History,
    'pending' : Pending,
  },
  data() {
    return {
      spinner:false,
      firstActiveTab:true,
      formReason:false,
      seen:false,
      msg :'',
      color:''
    };
  },
  filters: {},

  created () {},
  methods: {

    getFilterData(){
      this.data  = this.list;
    },

    actionCancel(){
      this.action= null;
      this.transactionRef= null;
      this.reason = null;
      this.formReason = false;
    },

    showFormAction(id,action){
      this.action = action;
      this.transactionRef = id;
      this.formReason = true;
    },
  }
};

</script>

<style>
.scroll-r{ overflow-y:scroll; }
</style>